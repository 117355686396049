import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import LinkedinIcon from '../../content/icons/Linkedin.svg'
import { 
  FlexTagContainer,
  AuthorContainer,
  Profile,
  MuiAvatar,
  Name,
  Bio,
  SocialLink,
  Icons
} from "../styles/blog"

export default function ContributorsPage({ data, location }) {  
  const authorNodes = data.allContributorYaml.nodes

  if (typeof window !== 'undefined'){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : 'gtm.dom',
      'pageType' : 'Autores',
      'hostName' : 'blog.rocky.ag',
      'pagePath' : location.pathname,
      'pageReferrer' : document.referrer,
    });
  }

  return (
    <Layout title={data.site.siteMetadata.title}>
      <SEO 
        title="Autores" 
      />
      <Header location={location} borderRadius={true} />

      <FlexTagContainer>
        <AuthorContainer>
          {authorNodes.map((author, index) => (
            <Profile key={index}>
              <MuiAvatar 
                src={author.picture.publicURL} 
                alt={author.name} 
              />
              <div style={{marginLeft: "10px", marginRight: "10px" }}>
                <Name>{author.name}</Name>
                <Bio>{author.bio}</Bio>
                <Icons>
                  <div>
                    <SocialLink href={author.linkedin}>
                      <LinkedinIcon 
                        height="35px" 
                        width="35px" 
                      />
                    </SocialLink>
                  </div>
                </Icons>
              </div>
            </Profile>  
          ))}
        </AuthorContainer>
        
      </FlexTagContainer>
    </Layout>
  )
}

ContributorsPage.propTypes = {
    data: PropTypes.shape({
      allContributorYaml: PropTypes.shape(
        PropTypes.shape({
          name: PropTypes.string,
          picture: PropTypes.shape({
            publicURL: PropTypes.string,
          }),
          bio: PropTypes.string,
          linkedin: PropTypes.string
        })     
      ),
      allMdx: PropTypes.shape({
        group: PropTypes.arrayOf(
          PropTypes.shape({
            fieldValue: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
            node: PropTypes.shape({
              frontmatter: PropTypes.shape({
                title: PropTypes.string
              })
            })
          }).isRequired
        ),
      }),
      site: PropTypes.shape({
        siteMetadata: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
      }),
    }),
  }

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContributorYaml {
      nodes {
        name
        bio
        picture {
          publicURL
        }
        linkedin
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___contributor) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter{
              title
            }
          }
        }
      }
    }
  }
`